<!-- 
	This is the tables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
	<div>
		<!-- Products Table -->
		<a-row :gutter="24" type="flex">

			<!-- Products Table Column -->
			<a-col :span="24" class="mb-24">

				<!-- Products Table Card -->
				<CardCategoriesTable
					:data="table1Data"
					:columns="table1Columns"
				></CardCategoriesTable>
				<!-- / Products Table Card -->
				<a-button class="fab" shape="circle" @click="addCategory">
					<font-awesome-icon icon="plus"/>
				</a-button>
			</a-col>
			<!-- / Products Table Column -->

		</a-row>
		<!-- / Products Table -->

	</div>
</template>

<script>

	// "Products" table component.
	import CardCategoriesTable from '../components/Cards/CardCategoriesTable' ;

	import request from '../utils/request';
	
	import {list} from '@/api/category';
	// "Products" table list of columns and their properties.
	const table1Columns = [
		{
			title: 'Name',
			dataIndex: 'name',
		},
		{
			title: 'Description',
			dataIndex: 'description',
			
		},
		{
			title: 'Products',
			dataIndex: 'products',
			scopedSlots: { customRender: 'countProduct'}
		},
		{
			title: '',
			dataIndex:'categoryid',
			scopedSlots: { customRender: 'editBtn' },
			width: 50,
		},
		{
			title: '',
			dataIndex:'categoryid',
			key:'m',
			scopedSlots: { customRender: 'delBtn' },
			width: 50,
		},
		{
			title: '',
			dataIndex:'categoryid',
			key:'n',
			scopedSlots: { customRender: 'navBtn' },
			width: 60,
		},
	];

	// "Authors" table list of rows and their properties.
	const table1Data = [];
	
	export default ({
		components: {
			CardCategoriesTable,
		},
		methods: {
			addCategory(){
				this.$router.push({ path: '/categoryedit', query: {categoryid:0}});
			}
		},
		data() {
			return {
				// Associating "Products" table data with its corresponding property.
				table1Data: table1Data,

				// Associating "Products" table columns with its corresponding property.
				table1Columns: table1Columns,
			}
		},
		mounted() {
			this.table1Data = [];
			list().then(data=>{
				this.table1Data = [];
				for (var i = 0; i < data.length; i++){
					if (data[i].categorytype==4){
						this.table1Data.push(data[i]);
					}
				}
			
			});
		},
	})

</script>

<style lang="scss">
</style>