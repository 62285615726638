<template>

	<!-- Projects Table Column -->
	<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
		<template #title>
			<a-row type="flex" align="middle">
				<a-col :span="24" :md="12">
					<h5 class="font-semibold m-0">Categories</h5>
				</a-col>
				<a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
				</a-col>
			</a-row>
		</template>
		<a-table :columns="columns" :data-source="data" :pagination="true">
			<template slot="name" slot-scope="text">
				<a>{{ text }}</a>
			</template>			

			<template slot="editBtn" slot-scope="row">
				<a-button shape="circle" @click="editcategory(row)" icon="edit">
				</a-button>
			</template>

			<template slot="navBtn" slot-scope="row">
				<a-button shape="circle" @click="categorydetail(row)" icon="right">
				</a-button>
			</template>

			<template slot="delBtn" slot-scope="row">
				<a-button shape="circle" @click="delcategory(row)" icon="delete">
				</a-button>
			</template>

			<template slot="countProduct" slot-scope="products">
				{{(products?products.length:0)}}
			</template>
		</a-table>

		
	</a-card>
	<!-- / Projects Table Column -->

</template>

<script>
	import {deletecategory} from '@/api/category';

	export default ({
		props: {
			data: {
				type: Array,
				default: () => [],
			},
			columns: {
				type: Array,
				default: () => [],
			},
		},
		data() {
			return {
				// Active button for the "Projects" table's card header radio button group.
				projectHeaderBtns: 'all',
			}
		},
		methods: {
			editcategory(id){
				this.$router.push({path:'/categoryedit',query:{categoryid:id}})
			},
			categorydetail(id){
				this.$router.push({path:'/categorydetail',query:{categoryid:id}})
			},
			delcategory(id){
				deletecategory(id).then(data=>{
					for (var i=0; i < this.data.length; i++)
						if (this.data[i].categoryid==id)
							this.data.splice(i,1);
				})
			}
		}
	})

</script>